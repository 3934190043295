<template>
  <section class="pa-0">
    <v-row>
        <div>
            <v-card-text class="pb-0">Fecha y hora de contratación</v-card-text>
            <v-card-text class="pt-2">{{datos.fechaHoraContratacion}}</v-card-text>
        </div>
        <div>
            <v-card-text class="pb-0">Número de orden de compra</v-card-text>
            <v-card-text class="pt-2">{{datos.numOrdenCompra}}</v-card-text>
        </div>
        <div>
            <v-card-text class="pb-0">Número interno de orden de compra</v-card-text>
            <v-card-text class="pt-2">{{datos.numInternoOrdenCompra}}</v-card-text>
        </div>
        <div>
            <v-card-text class="pb-0">Monto adjudicado</v-card-text>
            <v-card-text class="pt-2">{{datos.montoAdjudicado}}</v-card-text>
        </div>
    </v-row>
  </section>
</template>

<script>
export default {
    props: {
        datos: {
            required: true,
            type: Object
        }
    }
}
</script>

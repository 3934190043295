<template>
  <section>
    <v-row>
      <v-col cols="12">
        <div class="d-flex" :style="{ gap: '10px' }">
          <v-btn
            dark
            @click="documentosDialog = true"
            color="secondary"
            v-if="!haveRole('ROLE_OFICIAL_CUMPLIMIENTO')"
          >
            Agregar documento
          </v-btn>
          <v-btn
            dark
            @click="modalNotificacionGarantia = true"
            :disabled="!tieneGarantia"
            color="secondary"
            v-if="!haveRole('ROLE_OFICIAL_CUMPLIMIENTO') && seguimientoContrato.esAdministradorContrato"
          >
           Vencimiento Garantía
          </v-btn>
        </div>
      </v-col>
      <v-expansion-panels>
        <v-expansion-panel v-for="(item, i) in contratoDocs" :key="i">
          <v-expansion-panel-header> {{ item.key }} </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
              :headers="mostrarHeaders(item)"
              :items="item.value"
              no-data-text="No hay datos para mostrar"
              hide-default-footer
              :items-per-page="-1"
            >
            <template v-slot:[`item.recepcion`]="{ item }">
              {{ item.recepcion ? item.recepcion : 'No tiene registrada una recepción' }}
            </template>
              <template v-slot:[`item.nombre_archivo`]="{ item }">
                {{ nombreArchivo(item) }}
              </template>
              <template v-slot:[`item.fecha_emision`]="{ item }">
                {{ moment(item.fecha_emision).format("DD/MM/YYYY") }}
              </template>
              <template v-slot:[`item.acciones`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="
                        descargarDocumentoManejador({
                          disk: disk(item?.tipo_contrato),
                          ruta_documento: item?.ruta_archivo,
                        })
                      "
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-file-download-outline</v-icon>
                    </v-btn>
                  </template>

                  <span>Descargar</span>
                </v-tooltip>

                <!-- <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="!haveRole('ROLE_OFICIAL_CUMPLIMIENTO')"
                      @click="(deleteModal = true), (doc_selected = item.id)"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>

                  <span>Eliminar</span>
                </v-tooltip> -->
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- modal agregar documento -->
      <v-dialog
        persistent
        max-width="1000"
        class="modal"
        v-model="documentosDialog"
      >
        <v-card max-width="1000" max-height="1050">
          <v-btn @click="cerrarModal()" icon color="secondary">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
          <v-card-text class="px-6 px-sm-10">
            <v-row no-gutters class="px-sm-10">
              <v-col cols="12" class="mb-3 text-center text-sm-start">
                <span class="text-h5 secondary--text"> Agregar documento </span>
              </v-col>
              <v-col cols="12" class="my-1">
                <v-select
                  :items="tiposDocumentos"
                  item-value="id"
                  item-text="nombre"
                  outlined
                  v-model="infoArchivo.tipoDocumento"
                  label="Tipo de documento *"
                  clearable
                  @blur="$v.infoArchivo.tipoDocumento.$touch()"
                  :error-messages="tipoDocumentoError"
                />
              </v-col>
              <template v-if="infoArchivo.tipoDocumento">
                <v-col cols="12">
                  <v-text-field
                    v-model="archivoContrato.nombreArchivo"
                    outlined
                    label="Nombre del archivo *"
                    @input="$v.archivoContrato.nombreArchivo.$touch()"
                    @blur="$v.archivoContrato.nombreArchivo.$touch()"
                    :error-messages="nombreArchivoError"
                    clearable
                    maxlength="50"
                  />
                </v-col>
                <v-col cols="12">
                  <v-file-input
                    v-model="archivoContrato.archivo"
                    prepend-icon=""
                    placeholder="Adjuntar archivo *"
                    outlined
                    label="Archivo *"
                    @input="$v.archivoContrato.archivo.$touch()"
                    @blur="$v.archivoContrato.archivo.$touch()"
                    :error-messages="archivoError"
                    accept="application/pdf"
                    prepend-inner-icon="mdi-paperclip"
                  />
                </v-col>
              </template>
              <v-col
                cols="12"
                class="d-flex flex-column flex-sm-row justify-center"
              >
                <v-btn
                  color="secondary"
                  class="mx-8 mb-2 mb-sm-0"
                  text
                  @click="clearInfoArchivo(), cerrarModal()"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  :loading="loadingGuardarDocumento"
                  color="secondary"
                  class="white--text mx-8 px-8 mt-2 mt-sm-0"
                  @click="generarOtros"
                >
                  Guardar documento
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <dialogoConfirmacion
      :show="deleteModal"
      title="¿Desea eliminar este documento?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="deleteModal = false"
      @confirm="deleteDoc()"
    />

    <ConfirmationModalComponent
      description="¿Desea notificar sobre el vencimiento de la garantía?"
      :is-open="modalNotificacionGarantia"
      @confirm="notificarVencimientoGarantia"
      @cancel="modalNotificacionGarantia = false"
    />

  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { helpers, required } from "vuelidate/lib/validators";
import dialogoConfirmacion from "@/components/ConfirmationDialogComponent.vue";
import LegacyValidations from "@/utils/legacy-validations";
import MoneyTextFieldComponent from "../../../components/MoneyTextFieldComponent.vue";
import { convertToFormData } from "@/utils/data";
import ConfirmationModalComponent from "@/components/utils/ConfirmationModalComponent.vue";

const txtField = helpers.regex("txtField", /^[A-Za-zñÑáéíóúÁÉÍÓÚ0-9 ]*$/);

function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}

export default {
  name: "DocumentosCargadosComponent",
  components: {
    dialogoConfirmacion,
    MoneyTextFieldComponent,
    ConfirmationModalComponent,
  },
  validations: {
    // Validaciones para el formulario de orden de inicio
    infoArchivo: {
      tipoDocumento: { required },
    },
    // Validaciones para el formulario de orden de compra
    formOrdenDeCompra: {
      numero_orden: { required },
      observaciones: { required },
      copias_emitir: { required },
    },
    // Validaciones para el formulario de garantia
    formGarantia: {
      monto: { required },
      nombre_documento: { required, txtField },
      documento: {
        required,
        fileNameValid,
        fileSizeValid: LegacyValidations.maxFileSizeRule(),
      },
      vigencia_desde: { required },
      vigencia_hasta: { required },
    },
    // Validaciones para el formulario de otros documentos
    archivoContrato: {
      nombreArchivo: {
        required,
        txtField,
      },
      archivo: {
        required,
        fileNameValid,
        fileSizeValid: LegacyValidations.maxFileSizeRule(),
      },
    },
  },
  data: () => ({
    archivoContrato: {
      nombreArchivo: null,
      archivo: null,
    },
    infoArchivo: {
      clausula: null,
      fecha_emision: null,
      fecha_vigencia: null,
      tipoDocumento: null,
    },
    formGarantia: {
      monto: null,
      nombre_documento: null,
      documento: null,
      vigencia_desde: null,
      vigencia_hasta: null,
    },
    vigencia_format: {
      desde: null,
      hasta: null,
    },
    formOrdenDeCompra: {
      numero_orden: null,
      observaciones: null,
      copias_emitir: [],
    },
    menu1: false,
    menu2: false,
    menu3: false,
    menu4: false,
    documentosDialog: false,
    deleteModal: false,
    doc_selected: null,
    headersDocs: [
      {
        text: "Nombre",
        value: "nombre_archivo",
      },
      {
        text: "Recepción",
        value: "recepcion",
      },
      {
        text: "Tipo de documento",
        value: "tipo_contrato",
      },
      {
        text: "Fecha carga",
        align: "center",
        value: "fecha_emision",
      },
      {
        text: "Acciones",
        align: "center",
        value: "acciones",
        sortable: false,
      },
    ],
    headersDocs2: [
      {
        text: "Nombre",
        value: "nombre_archivo",
      },
      {
        text: "Tipo de documento",
        value: "tipo_contrato",
      },
      {
        text: "Fecha carga",
        align: "center",
        value: "fecha_emision",
      },

      {
        text: "Acciones",
        align: "center",
        value: "acciones",
        sortable: false,
      },
    ],
    contratoDocs: [],
    tiposDocumentos: [],
    itemsCopias: [
      { id: 1, nombre: "UCP" },
      { id: 2, nombre: "Contratista" },
      { id: 3, nombre: "Finanzas" },
    ],
    loadingGuardarDocumento: false,
    segundo_error: false,
    modalNotificacionGarantia: false,
  }),
  computed: {
    ...mapState(["selectedUnidad"]),
    ...mapState("seguimientoOrden", ["seguimientoContrato"]),
    ...mapState("procesoCompra", ["idOrdenCompra"]),
    tipoDocumentoError() {
      const errors = [];
      if (!this.$v.infoArchivo.tipoDocumento.$dirty) return errors;
      !this.$v.infoArchivo.tipoDocumento.required &&
        errors.push("El tipo de documento es requerido");
      return errors;
    },

    // condicionales orden de compra
    numeroOrdenErrors() {
      const errors = [];
      if (!this.$v.formOrdenDeCompra.numero_orden.$dirty) return errors;
      !this.$v.formOrdenDeCompra.numero_orden.required &&
        errors.push("El número de orden es requerido");
      return errors;
    },
    observacionesErrors() {
      const errors = [];
      if (!this.$v.formOrdenDeCompra.observaciones.$dirty) return errors;
      !this.$v.formOrdenDeCompra.observaciones.required &&
        errors.push("Las observaciones son requeridas");
      return errors;
    },
    copiasEmitirErrors() {
      const errors = [];
      if (!this.$v.formOrdenDeCompra.copias_emitir.$dirty) return errors;
      !this.$v.formOrdenDeCompra.copias_emitir.required &&
        errors.push("Las copias a emitir son requeridas");
      return errors;
    },

    // condicionales garantia
    montoError() {
      const errors = [];
      if (!this.$v.formGarantia.monto.$dirty) return errors;
      !this.$v.formGarantia.monto.required &&
        errors.push("El monto es requerido");
      return errors;
    },
    nombreDocumentoError() {
      const errors = [];
      if (!this.$v.formGarantia.nombre_documento.$dirty) return errors;
      !this.$v.formGarantia.nombre_documento.required &&
        errors.push("El nombre del documento es requerido");
      !this.$v.formGarantia.nombre_documento.txtField &&
        errors.push("El nombre del documento no es válido");
      return errors;
    },
    documentoError() {
      const errors = [];
      if (!this.$v.formGarantia.documento.$dirty) return errors;
      !this.$v.formGarantia.documento.required &&
        errors.push("El documento es requerido");
      !this.$v.formGarantia.documento.fileNameValid &&
        errors.push("El archivo debe ser un PDF");
      !this.$v.formGarantia.documento.fileSizeValid &&
        LegacyValidations.pushToErrorsArray(errors);

      return errors;
    },
    vigenciaDesdeError() {
      const errors = [];
      if (!this.$v.formGarantia.vigencia_desde.$dirty) return errors;
      !this.$v.formGarantia.vigencia_desde.required &&
        errors.push("La vigencia es requerida");
      return errors;
    },
    vigenciaHastaError() {
      const errors = [];
      if (!this.$v.formGarantia.vigencia_hasta.$dirty) return errors;
      !this.$v.formGarantia.vigencia_hasta.required &&
        errors.push("La vigencia es requerida");
      return errors;
    },

    // condicionales otros documentos
    nombreArchivoError() {
      const errors = [];
      if (!this.$v.archivoContrato.nombreArchivo.$dirty) return errors;
      !this.$v.archivoContrato.nombreArchivo.required &&
        errors.push("El nombre del archivo es requerido");
      !this.$v.archivoContrato.nombreArchivo.txtField &&
        errors.push("El nombre del archivo no es válido");

      return errors;
    },
    archivoError() {
      const errors = [];
      if (!this.$v.archivoContrato.archivo.$dirty) return errors;
      !this.$v.archivoContrato.archivo.required &&
        errors.push("El archivo es requerido");
      !this.$v.archivoContrato.archivo.fileNameValid &&
        errors.push("El archivo debe ser un PDF");
      !this.$v.archivoContrato.archivo.fileSizeValid &&
        LegacyValidations.pushToErrorsArray(errors);

      return errors;
    },
    tieneGarantia(){
      const garantia = this.contratoDocs.find(doc => doc.key === 'Garantías');
      return garantia?.value?.length > 0
    }
  },
  methods: {
    mostrarHeaders(item) {
      if (item.key === 'Prórrogas' || item.key === 'Modificativas' || item.key === 'General' || item.key === 'Garantías') {
        return this.headersDocs2;
      } else {
        return this.headersDocs;
      }
    },
    nombreArchivo(item) {
      return item.nombre_archivo
        ? item.nombre_archivo.split("-")[0].split("_").join(" ")
        : `Documento de ${item.tipo_contrato.toLowerCase()}`;
    },
    async descargarDocumentoManejador(carga, disk = null) {
      if (disk !== null) {
        carga.disk = disk;
      }
      try {
        await this.descargarArchivoProceso(carga, false);
      } catch (e) {
        if ((this.segundo_error = false)) {
          this.segundo_error = true;
          await this.descargarDocumentoManejador(carga, "contratos");
        } else {
          this.segundo_error = false;
          this.pushAppMessage({
            show: true,
            message: "Error al descargar el archivo",
            type: "error",
          });
        }
      }
    },
    ...mapActions("procesoCompraDoc", ["descargarArchivoProceso"]),
    // handler para el boton de guardar
    guardarDocumento() {
      const id_tipo_documento = Number(this.infoArchivo?.tipoDocumento);
      switch (id_tipo_documento) {
        case 2:
          this.generarOrdenDeCompra();
          break;
        case 5:
          this.generarOrdenDeInicio();
          break;
        case 7:
          this.generarGarantia();
          break;
        default:
          this.generarOtros();
          break;
      }
    },
    disk(tipo_contrato) {      
      switch (tipo_contrato) {
        case "Contrato":
          return "contratos";
        case "Otros":
          return "contratos";
        case "Garantía":
          return "garantias";
        case "Informe del administrador de contrato":
          return "editarContrato";
        case "Documento de aceptación del contratista":
          return "editarContrato";
        case "Aprobación máxima autoridad":
          return "editarContrato";
        case "Documento de modificativa":
          return "editarContrato";
        case "Comprobante de pago":
          return "contratos";
        case "Condiciones de pago":
          return "contratos";
        case "Documentos forma de pago":
          return "contratos";
        case "Informe de seguimiento":
          return "contratos";
        case "Orden de compra":
          return "contratos";  
        case "Actas de recepción parcial":
          return "contratos";  
        default:
          return "local";
      }
    },
    async generarOrdenDeCompra() {
      try {
        // Validamos el formulario
        this.$v.formOrdenDeCompra.$touch();

        if (!this.$v.formOrdenDeCompra.$invalid) {
          this.loadingGuardarDocumento = true;
          const params = {
            id_institucion: this.selectedUnidad.instituciones.id,
            observaciones: this.formOrdenDeCompra.observaciones,
            array_copias: this.formOrdenDeCompra.copias_emitir,
          };

          const response =
            await this.services.ContratoService.generarOrdenDeCompra(
              this.idOrdenCompra,
              params
            );

          if (response.status == 201) {
            this.loadingGuardarDocumento = false;
            this.clearInfoArchivo();
            this.getDocumentosContrato();
            // Mostrar mensaje de éxito
            this.temporalAlert({
              show: true,
              message: "Orden de compra generada exitosamente",
              type: "success",
            });
            // Descargar el archivo
            const url = window.URL.createObjectURL(new Blob([response?.data]));
            const link = document.createElement("a");
            link.setAttribute(
              "download",
              `Orden de compra ${this.idOrdenCompra}.pdf`
            );
            link.setAttribute("href", url);
            document.body.appendChild(link);
            link.click();
            // Cerrar el modal
            this.documentosDialog = false;
          }
        }
      } catch (error) {
        this.temporalAlert({
          show: true,
          message: "Error al generar la orden de compra",
          type: "error",
        });
      } finally {
        this.loadingGuardarDocumento = false;
      }
    },

    async generarOrdenDeInicio() {
      try {
        this.$v.$touch();
        if (!this.$v.infoArchivo.$invalid) {
          this.loadingGuardarDocumento = true;
          const params = {
            id_institucion: this.selectedUnidad.instituciones.id,
            clausula: this.infoArchivo.clausula,
            fecha_emision: this.infoArchivo.fecha_emision,
            fecha_inicio_vigencia: this.infoArchivo.fecha_vigencia,
            id_tipo_documento_orden: this.infoArchivo.tipoDocumento,
          };
          const { status, data } =
            await this.services.ContratoService.guardarDocumentosContrato(
              this.idOrdenCompra,
              params
            );
          if (status === 200 || status === 201) {
            this.clearInfoArchivo();
            this.getDocumentosContrato();
            this.downloadDoc(data);
            this.temporalAlert({
              show: true,
              message: "Documento agregado exitosamente",
              type: "success",
            });
            this.documentosDialog = false;
          }
        }
      } catch (error) {
        this.temporalAlert({
          show: true,
          message: "Error al generar la orden de inicio",
          type: "error",
        });
      } finally {
        this.loadingGuardarDocumento = false;
      }
    },

    async generarGarantia() {
      try {
        this.$v.formGarantia.$touch();
        if (this.$v.formGarantia.$invalid) {
          this.temporalAlert({
            message: "Por favor, complete los campos requeridos",
            show: true,
            type: "error",
          });
          return;
        } else {
          this.loadingGuardarDocumento = true;
          const formData = new FormData();
          formData.append("monto", this.formGarantia.monto);
          formData.append(
            "nombre_documento",
            this.formGarantia.nombre_documento
          );
          formData.append("documento", this.formGarantia.documento);
          formData.append("vigencia_desde", this.formGarantia.vigencia_desde);
          formData.append("vigencia_hasta", this.formGarantia.vigencia_hasta);

          this.clearInfoArchivo();
          this.getDocumentosContrato();
          this.documentosDialog = false;

          const { status } = await this.services.ContratoService.postGarantia(
            this.$route.params.idContrato,
            formData
          );
          if (status === 200) {
            this.loadingGuardarDocumento = false;
            this.clearInfoArchivo();
            this.getDocumentosContrato();
            this.temporalAlert({
              type: "success",
              show: true,
              message: "Documento agregado exitosamente",
            });
            this.documentosDialog = false;
          }
        }
      } catch (error) {
        this.temporalAlert({
          show: true,
          message: "Error al generar el documento",
          type: "error",
        });
      } finally {
        this.loadingGuardarDocumento = false;
      }
    },

    // funcion para otro tipo de documentos
    async generarOtros() {
      try {
        this.$v.archivoContrato.$touch();
        this.$v.infoArchivo.$touch();

        if (
          !this.$v.archivoContrato.$invalid &&
          !this.$v.infoArchivo.$invalid
        ) {
          this.loadingGuardarDocumento = true;
          let formData = new FormData();
          formData.append(
            "id_tipo_documento_orden",
            this.infoArchivo.tipoDocumento
          );
          formData.append("nombre_archivo", this.archivoContrato.nombreArchivo);
          formData.append("documento", this.archivoContrato.archivo);
          const { status } =
            await this.services.ContratoService.postDocumentosContrato(
              this.idOrdenCompra,
              formData
            );
          if (status === 201) {
            this.loadingGuardarDocumento = false;
            this.clearInfoArchivo();
            await this.getDocumentosContrato();
            this.pushAppMessage({
              type: "success",
              show: true,
              message: "Documento agregado exitosamente",
            });
            this.documentosDialog = false;
          }
        }
      } catch (error) {
        this.pushAppMessage({
          show: true,
          message: "Error al generar el documento",
          type: "error",
        });
      } finally {
        this.loadingGuardarDocumento = false;
      }
    },

    // funcionalidades de la tabla
    async deleteDoc() {
      const { status } =
        await this.services.ContratoService.deleteDocumentoContrato(
          this.doc_selected
        );
      if (status === 204) {
        this.getDocumentosContrato();
        this.temporalAlert({
          show: true,
          message: "Documento eliminado exitosamente",
          type: "success",
        });
        this.deleteModal = false;
      }
    },
    clearInfoArchivo() {
      // orden de inicio
      this.infoArchivo.clausula = null;
      this.infoArchivo.fecha_emision = null;
      this.infoArchivo.fecha_vigencia = null;
      this.infoArchivo.tipoDocumento = null;

      // orden de compra
      this.archivoContrato.nombreArchivo = null;
      this.archivoContrato.archivo = null;

      // garantia
      this.formGarantia.monto = null;
      this.formGarantia.nombre_documento = null;
      this.formGarantia.documento = null;
      this.formGarantia.vigencia_desde = null;
      this.formGarantia.vigencia_hasta = null;

      // otros documentos
      this.formOrdenDeCompra.observaciones = null;

      this.$v.$reset();
    },
    cerrarModal() {
      this.$v.$reset();
      this.documentosDialog = false;
    },

    // catalogos
    async getDocumentosContrato() {
      const { data } =
        await this.services.ContratoService.getDocumentosContrato(
          this.idOrdenCompra,
          {
            pagination: "false",
          }
        );

      const documentos = [];
      Object.keys(data).forEach((key) => {
        documentos.push({
          key,
          value: data[key],
        });
      });

      this.contratoDocs = documentos;
    },
    async getTiposDocumentos() {
      const { data } =
        await this.services.ContratoService.obtenerTiposDocumentosPorContrato(
          this.seguimientoContrato?.contrato.id_tipo_contrato
        );

      this.tiposDocumentos = data;
    },
    async obtenerInfoOrdenCompra() {
      const response =
        await this.services.ContratoService.obtenerInfoOrdenDeCompra(
          this.idOrdenCompra
        );

      if (response.status === 200) {
        this.formOrdenDeCompra.numero_orden = response.data.numero;
        this.formOrdenDeCompra.observaciones = "";
        this.formOrdenDeCompra.copias_emitir = response.data.copias;
      }
    },
    async notificarVencimientoGarantia(data) {
      const idContrato = this.$route.params.idContrato;
      const formData = convertToFormData(data)
      await this.services.ContratoService.notificarVencimiento(idContrato, formData);
      this.modalNotificacionGarantia = false;
    },
  },
  watch: {
    "formGarantia.vigencia_desde"(val) {
      if (val !== null) {
        this.vigencia_format.desde = this.moment(val).format("DD/MM/YYYY");
      } else {
        this.formGarantia.vigencia_hasta = null;
        this.vigencia_format.desde = null;
      }
    },
    "formGarantia.vigencia_hasta"(val) {
      if (val !== null) {
        this.vigencia_format.hasta = this.moment(val).format("DD/MM/YYYY");
      } else {
        this.vigencia_format.hasta = null;
      }
    },
  },
  async created() {
    await this.getDocumentosContrato();
    await this.getTiposDocumentos();
    await this.obtenerInfoOrdenCompra();
  },
};
</script>
